import {
    getExternalChannels, 
    getExternalVideos, 
    getExternalChannel, 
    getExternalVideo, 
    getManyExternalChannels, 
    createExternalChannel, 
    deleteExternalChannel, 
    deleteExternalChannels,
    getExternalTopTags,
    getExternalSnapshots,
    addExternalSnapshot,
    deleteExternalSnapshot,
    createRetentionSpike,
    deleteRetentionSpike,
    setIncludeShorts
} from './externalChannelsDataSource'
import{
    getOwnChannels,
    getOwnChannel,
    createOwnVideo,
    deleteOwnVideos,
    getOwnVideos,
    getOwnVideo,
    getOwnChannelAverage,
    getOwnVideoCreators,
    createVideoCreator,
    updateVideoCreator,
    deleteVideoCreators,
    getLatestVideoPerformances,
    getRelatedVideos,
    updateRetentionThreshold,
    updateThumbnailThreshold,
    updateVideoIdeasUpdateFrequency,
    updateTrendsetterThreshold,
    setFilmingDate
} from './ownChannelsDataSource'
import {
    getDailyViewAnalytics,
    getViewAnalyticsLastXDays,
    getMonthlyViewAnalytics,
    getViewAnalyticsByVideoCreatorId,
    getShortsViewAnalyticsLastXDays,
    getMonthlyShortsViewAnalytics
} from './viewsDataSource'
import{
    getTikTokUsers,
    createTikTokUser,
    deleteTikTokUser,
    deleteTikTokUsers,
    getTikTokChallenges,
    getTikTokVideos,
    getTikTokVideo
} from './tikTokDataSource'
import{
    getProposals,
    getProposal,
    createProposal,
    updateProposal,
    deleteProposal,
    getProposalComments,
    createProposalComment,
    deleteProposalImage
} from './proposalsDataSource'
import { 
    createTrendingTag, 
    deleteTrendingTag,
    deleteTrendingTags, 
    getTrendingTags,
    getTrendingTag,
    getRelatedTrends
} from './googleTrendsDataSource'
import {
    getUsers,
    getUserRoles,
    getUserChannels,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    getRoles,
    getRole,
    deleteRole,
    getOwnedChannels,
    createRole,
    updateRole
} from './userDataSource'
import {
    createThumbnails,
    getThumbnailsForVideo,
    updateThumbnails,
    deleteThumbnails
} from './thumbnailsDataSource'
import {
    chat,
    getChatHistory,
    addChatHistory,
    deleteChatGptHistory,
    combineImages,
    createImage,
    createImageVariations,
    getAiGeneratedImages,
    deleteAiGeneratedImage
} from './ChatGptDataSource'
import {
    createProject,
    getProjects,
    getProject,
    getApiKeys,
    createApiKey,
    deleteApiKey,
    updateProject,
    deleteProject,
    deleteProjectChannels
} from './projectsDataSource'
import {
    translate
} from './utilsDataSource'
import {
    getVideoIdeas,
    reserveVideoIdea,
    saveVideoIdea,
    getSavedVideoIdeas,
    deleteSavedVideoIdea,
    generateThumbnailIdeas
} from './videoIdeasDataSource'
import {
    getPrompts,
    createPrompt,
    deletePrompt,
    updatePrompt
} from './promptsDataSource'

import {
    getOwnShorts,
    getOwnShortsChannels
} from './shorts/ownShortsDataSource'
import {    
    getExternalShorts
} from './shorts/externalShortsDataSource'

import * as shortsVideoCreatorDataSource from './shorts/shortsVideoCreatorsDataSource'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getList : async (resource, params) => {
        switch(resource){
            case "externalChannels":return await getExternalChannels(params)
            case "externalVideos": return await getExternalVideos(params)
            case "ownChannels": return await getOwnChannels(params)
            case "ownVideos": return await getOwnVideos(params)
            case "tikTokUsers": return await getTikTokUsers(params)
            case "tikTokChallenges": return await getTikTokChallenges(params)
            case "tikTokVideos": return await getTikTokVideos(params)
            case "videoProposals": return await getProposals("video",params)
            case "propsProposals": return await getProposals("props",params)
            case "trendingTags": return await getTrendingTags(params)
            case "userData": return await getUsers(params)
            case "roles": return await getRoles(params)
            case "userChannels": return await getOwnedChannels(params)
            case "viewsAnalytics": return await getDailyViewAnalytics(params)
            case"ViewAnalyticsLastXDays": return await getViewAnalyticsLastXDays(params)
            case "ViewAnalyticsShortsLastXDays": return await getShortsViewAnalyticsLastXDays(params)
            case "monthlyViewsAnalytics": return await getMonthlyViewAnalytics(params)
            case "monthlyShortsViewAnalytics": return await getMonthlyShortsViewAnalytics(params)
            case "chatGpt": return await getChatHistory(params)
            case "latestVideoPerformances": return await getLatestVideoPerformances(params)
            case "relatedVideos": return await getRelatedVideos(params)
            case "projects": return await getProjects()
            case "apiKeys": return await getApiKeys(params)
            case "savedVideoIdeas": return await getSavedVideoIdeas(params)
            case "prompts": return await getPrompts(params)
            case "aiGeneratedImages": return await getAiGeneratedImages(params)
            case "ownShortChannels": return await getOwnShortsChannels(params)
            case "ownShorts": return await getOwnShorts(params)
            case "externalShorts": return await getExternalShorts(params)
            default:return //Add Error Handling
        }
    },
    getOne : async (resource, params) => {
        switch(resource){
            case "externalChannels":return await getExternalChannel(params) 
            case "externalVideos":return await getExternalVideo(params)
            case "ownChannels": return await getOwnChannel(params)
            case "ownVideos": return await getOwnVideo(params)
            case "tikTokVideos": return await getTikTokVideo(params)
            case "videoProposals": return await getProposal(params)
            case "propsProposals": return await getProposal(params)
            case "trendingTags": return await getTrendingTag(params)
            case "userData": return await getUser(params)
            case "roles": return await getRole(params)
            case "viewsAnalytics": return await getViewAnalyticsByVideoCreatorId(params)
            case "thumbnails": return await getThumbnailsForVideo(params)
            case "projects": return await getProject(params)
            default: return //Add Error Handling
        }
    },
    getMany: async (resource, params) => {
        switch(resource){
            case "externalChannels":return await getManyExternalChannels(params)
            case "videoProposalComments": return await getProposalComments(params)
            case "propsProposalComments": return await getProposalComments(params)
            case "relatedTrends": return await getRelatedTrends(params)
            default: return //Add Error Handling
        }
    },
    getManyReference: (resource, params) => {console.log("GetManyReference called")},
    create : async (resource, params) =>{
        try{
            switch(resource){
                case "ownVideos": return await createOwnVideo(params)
                case "externalChannels":return await createExternalChannel(params)
                case "tikTokUsers": return await createTikTokUser(params)
                case "videoProposals": return await createProposal("video", params)
                case "propsProposals": return await createProposal("props", params)
                case "videoProposalComments": return await createProposalComment(params)
                case "propsProposalComments": return await createProposalComment(params)
                case "trendingTags": return await createTrendingTag(params)
                case "userData": return await createUser(params)
                case "roles": return await createRole(params)
                case "videoCreator": return await createVideoCreator(params)
                case "retentionSpikes": return await createRetentionSpike(params)
                case "thumbnails": return await createThumbnails(params)
                case "apiKeys": return await createApiKey(params)
                case "projects": return await createProject(params)
                case "savedVideoIdeas": return await saveVideoIdea(params)
                case "prompts": return await createPrompt(params)
                case "imageCombiner": return await combineImages(params)
                case "imageVariator": return await createImageVariations(params)
                case "imageCreator": return await createImage(params)
                default: return //Add Error Handling
            }
        }catch(e){
            //Promise.reject(e)
            throw(e)
        }
    },
    update: async (resource, params) =>{
        switch(resource){
            case "videoProposals": return await updateProposal(params)
            case "propsProposals": return await updateProposal(params)
            case "userData": return await updateUser(params)
            case "roles": return await updateRole(params)
            case "videoCreator": return await updateVideoCreator(params)
            case "thumbnails": return await updateThumbnails(params)
            case "projects": return await updateProject(params)
            case "prompts": return await updatePrompt(params)
            default:return //Add Error Handling
        }
    },
    delete : async (resource, params) =>{
        switch(resource){
            case "externalChannels":return await deleteExternalChannel(params)
            case "tikTokUsers":return await deleteTikTokUser(params)
            case "videoProposals":return await deleteProposal(params)
            case "propsProposals":return await deleteProposal(params)
            case "trendingTags":return await deleteTrendingTag(params)
            case "userData":return await deleteUser(params)
            case "roles":return await deleteRole(params)
            case "retentionSpikes":return await deleteRetentionSpike(params)
            case "thumbnails":return await deleteThumbnails(params)
            case "chatGpt":return await deleteChatGptHistory(params)
            case "apiKeys":return await deleteApiKey(params)
            case "projects":return await deleteProject(params)
            case "projectChannels":return await deleteProjectChannels(params)
            case "savedVideoIdeas":return await deleteSavedVideoIdea(params)
            case "prompts":return await deletePrompt(params)
            case "aiGeneratedImages":return await deleteAiGeneratedImage(params)
            default:return //Add Error Handling
        }
    },
    deleteMany : async (resource, params) =>{
        const ids = params.ids
        const project = params?.meta?.project

        switch(resource){
            case "ownVideos": return await deleteOwnVideos(ids)
            case "externalChannels":return await deleteExternalChannels(project, ids)
            case "tikTokUsers":return await deleteTikTokUsers(project, ids)
            case "trendingTags":return await deleteTrendingTags(project, ids)
            case "videoCreator":return await deleteVideoCreators(ids)
            default:return //Add Error Handling
        }
    },
    getTopTags : async (resource, params) =>{
        switch(resource){
            case "externalChannels":return await getExternalTopTags(params)
            default:return //Add Error Handling
        }
    },
    // GET DATA PROVIDER HOOK BY  const dataProvider = useDataProvider();
    setComparableVideos : async (channel, videos) =>{
        //TO BE IMPLEMENTED
    },
    setComparableVideo : async (channel, video) =>{
        const currentVideos = localStorage.getItem(channel)
        if (currentVideos === null){
            const data = [{video:video}]
            localStorage.setItem(channel, JSON.stringify(data))
            return {data}
        }
        let comparableVideos = JSON.parse(currentVideos)
        comparableVideos.push({video:video})
        while (comparableVideos.length > 4)
            comparableVideos.shift()
        comparableVideos = JSON.stringify(comparableVideos)
        localStorage.setItem(channel, comparableVideos)
        //console.log(comparableVideos)
        return {data:comparableVideos}
    },
    getComparableVideos : async (channel) =>{
        return {data:JSON.parse(localStorage.getItem(channel))}
    },
    deleteComparableVideos : async (channel) =>{
        return {data:localStorage.removeItem(channel)}
    },
    deleteComparableVideo : async (channel, video) =>{
        const currentVideos = localStorage.getItem(channel)
        let comparableVideos = JSON.parse(currentVideos)
        //console.log(comparableVideos)
        for (let i = 0; i < comparableVideos.length; i++){
            if(comparableVideos[i].video.id === video.id)
                comparableVideos.splice(i,1)
        }
        if(comparableVideos.length === 0)
            localStorage.removeItem(channel)
        comparableVideos = JSON.stringify(comparableVideos)
        localStorage.setItem(channel, comparableVideos)
        return {data:comparableVideos}
    },
    getExternalSnapshots : async (resource, params) =>{
        switch(resource){
            case "externalChannels":
                return await getExternalSnapshots(params)
            default:
                return //Add Error Handling
        }
    },
    addExternalSnapshot : async (resource, params) =>{
        switch(resource){
            case "externalChannels":
                return await addExternalSnapshot(params)
            default:
                return //Add Error Handling
        }
    },
    deleteExternalSnapshot : async (resource, params) =>{
        switch(resource){
            case "externalChannels":
                return await deleteExternalSnapshot(params)
            default:
                return //Add Error Handling
        }
    },
    getChannelAverage : async(resource, params) =>{
        switch(resource){
            case "ownChannels":
                return await getOwnChannelAverage(params)
            default:
                return //Add Error Handling
        } 
    },
    deleteFile :async(resource, params) =>{
        switch(resource){
            case "proposalImages":
                return await deleteProposalImage(params)
            default:
                return //Add Error Handling
        }
    },
    getUserRoles : async (resource, params) =>{
        switch(resource){
            case "userData":
                return await getUserRoles(params)
            default:
                return //Add Error Handling
        }
    },
    getUserChannels : async (resource, params) =>{
        switch(resource){
            case "userChannels":
                return await getUserChannels(params)
            default:
                return //Add Error Handling
        }
    },
    getOwnVideoCreators : async (params) =>{
        return await getOwnVideoCreators(params)
    },
    getVideoCreatorsForShort : async (params) =>{
        return await shortsVideoCreatorDataSource.getVideoCreatorsForShort(params)
    },
    addVideoCreatorsForShort : async (params) =>{
        return await shortsVideoCreatorDataSource.addVideoCreatorsForShort(params)
    },
    updateVideoCreatorsForShort : async (params) =>{
        return await shortsVideoCreatorDataSource.updateVideoCreatorsForShort(params)
    },
    deleteVideoCreatorsForShort : async (params) =>{
        return await shortsVideoCreatorDataSource.deleteVideoCreatorsForShort(params)
    },
    getRolesForShorts : async () =>{
        return await shortsVideoCreatorDataSource.getRolesForShorts()
    },
    chat: async(oldMessages, newMessage) =>{
        return await chat(oldMessages, newMessage)
    },
    saveChatGptConversation: async(params) =>{
        return await addChatHistory(params)
    },
    updateRetentionThreshold: async(params) =>{
        return await updateRetentionThreshold(params)
    },
    updateThumbnailThreshold: async(params) =>{
        return await updateThumbnailThreshold(params)
    },
    translate: async(videos) =>{
        return await translate(videos)
    },
    getVideoIdeas: async(ownedChannelsId) =>{
        return await getVideoIdeas(ownedChannelsId)
    },
    reserveVideoIdea: async(id, ownedChannelsId, reserverEmail) =>{
        return await reserveVideoIdea(id, ownedChannelsId, reserverEmail)
    },
    setDefaultChannel : async (channel) =>{
        localStorage.setItem("defaultChannel", JSON.stringify(channel))
            
    },
    getDefaultChannel : async () =>{
        return Promise.resolve(JSON.parse(localStorage.getItem("defaultChannel")))
    },
    setIncludeShorts : async (project, channelId, includeShorts) =>{    
        return await setIncludeShorts(project, channelId, includeShorts)
    },
    updateVideoIdeasUpdateFrequency: async (params) =>{
        return updateVideoIdeasUpdateFrequency(params)
    },
    updateTrendsetterThreshold: async (params) =>{
        return updateTrendsetterThreshold(params)
    },
    generateThumbnailIdeas: async (params) =>{
        return generateThumbnailIdeas(params)
    },
    setFilmingDate: async (videoId, filmingDate) =>{
        return await setFilmingDate(videoId, filmingDate)
    }
}
