import React, { useEffect } from 'react';
import { 
    SimpleFormIterator, 
    Create,
    Datagrid,
    TextField,
    SimpleForm,
    ImageField,
    TextInput,
    ArrayInput,
    ImageInput,
    TopToolbar,
    CreateButton,
    FormDataConsumer,
    NumberInput,
    useRecordContext,
    useUpdate,
    RecordContextProvider,
    ArrayField,
    useShowContext,
    useShowController,
    SimpleShowLayout,
    SaveButton,
    useGetOne,
    useDataProvider,
    useNotify
    } from 'react-admin';
import MaterialButton from '@mui/material/Button';
import { Card } from "@mui/material";
import {TextField as MuiTextfield} from '@mui/material';

export const ThumbnailsCreate = () => (
    <Create redirect="edit">
      <SimpleForm>
        <TextInput source="videoId" label="Video Id" />
        <ImageInput source="images" label="Images" accept="image/*" maxSize={2000000} multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )

  export const ThumbnailsShow = ({videoId}) => {
    const videoRecord = useShowContext()
    const {isLoading,record, error} = useShowController({ resource: 'thumbnails', id: videoId})
    if (isLoading) {
        return <div>Loading Thumbnails...</div>;
    }
    if (error) {
        return <div>Error loading Thumbnails!</div>;
    }
    return (
        <RecordContextProvider value={record}>
            <h4>Thumbnails</h4>
            <TopToolbar>
                <CreateButton />
            </TopToolbar>
            <SimpleShowLayout>
                <ArrayField source="data" label=''>
                    <Datagrid bulkActionButtons={false}>
                        <ImageField source="url" label="" />
                        <IsActive />
                        <TextField source="ctr"  />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </RecordContextProvider>
    );
}

  export const ThumbnailsEdit = ({id,videoId}) =>{ 
    const {data, loading, error} = useGetOne('thumbnails', {id:videoId})
    const dataProvider = useDataProvider();
    const[isupdating, setIsUpdating] = React.useState(false)
    const notify = useNotify();
    const handleSave = (newData) => {
        (async () => {
            try{
                setIsUpdating(true)
                await dataProvider.update('thumbnails', {id:videoId, data: data, newData: newData})
                notify("Thumbnails updated")
                setIsUpdating(false)
                if (typeof window.plausible === 'function') {
                    const userEmail = localStorage.getItem('email')
                    window.plausible('Thumbnails updated', {props: {user: userEmail, videoId: videoId}})
                }

                window.location.reload(true)
            }catch(error){
                notify("Error updating Thumbnails")
                setIsUpdating(false)
            }
        })()
    }
    if (loading || isupdating) return <div>Loading...</div>;
    return (
    <Card>
        <h4>Thumbnails</h4>
    <SimpleForm record={data} toolbar={<Toolbar/>} onSubmit={handleSave}>
        <ArrayInput source="data" label = "Priority" >
               <SimpleFormIterator disableAdd inline >
                <FormDataConsumer >
                    {({ scopedFormData,getSource }) => (  
                    <div style={scopedFormData.active === 1 ? {backgroundColor:"#63B4B8"} : {backgroundColor:"transparent"}}>
                        {scopedFormData.active === 1 ? <b style={{margin:"5px"}}>Active Thumbnail</b>
                        : <SetActiveButton priority ={scopedFormData.priority}  />}
                        <Image record={scopedFormData} getSource={getSource} />                         
                        <NumberInput sx={{margin:"5px"}} source={getSource('ctr')} label="CTR" disabled />
                        <TextInput sx={{margin:"5px"}} source={getSource('activationDate')} label="Date Activated" disabled />
                        <TextInput sx={{margin:"5px"}} source={getSource('deactivationDate')} label="Date Deactivated" disabled />
                    </div>                      
                    )}  
                </FormDataConsumer>
               </SimpleFormIterator>
        </ArrayInput>
        <ImageInput source="images" label="Images" accept="image/*" maxSize={2000000} multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>
  </Card>
  )
  }

  const Toolbar = () => (<TopToolbar><SaveButton /></TopToolbar>)

  const IsActive = () => {
    const record = useRecordContext();
    if(!record) return null;
    if(record.active) return ( <b style={{color:"green"}}>Active Thumbnail</b>)
    return <></>
  }
  const SetActiveButton = ({priority}) => {
    const record = useRecordContext();
    const [update, { isLoading, error }] = useUpdate();
    const handleClick = () => {
        if(record){
            let newRecord = {...record};
            newRecord.data.forEach(element => {
                if(element.active === 1){
                    element.deactivationDate = new Date().toISOString().replace('T', ' ').replace('Z', '')
                    element.setActiveBy = 'System'
                }
                element.active = 0;
            });
            newRecord.data[priority-1].active = 1;
            newRecord.data[priority-1].activationDate = new Date().toISOString().replace('T', ' ').replace('Z', '')
            newRecord.data[priority-1].deactivationDate = ""
            newRecord.data[priority-1].setActiveBy = localStorage.getItem('email')
            //console.log(newRecord)
            update('thumbnails', {id:record.id, data:newRecord, previousData:record});
            if (typeof window.plausible === 'function') {
                const userEmail = localStorage.getItem('email')
                window.plausible('Set new Thumbnail active', {props: {user: userEmail}})
            }
        }
    }
    if(!record || !priority) return null;
    return (
        <MaterialButton disabled={isLoading} onClick ={handleClick}>Set Active</MaterialButton>
    )
  }

  const Image = ({record, getSource}) => {
    if(!record) return null;
    return (<div style={{display:"flex", justifyContent:"start", flexDirection:"row"}}>
    <ImageField source="url" record={record} sx={{display:"flex"}} /> 
    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
        <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start",margin:"0"}}>
            <TextInput label = "Uploaded by" source={getSource("uploadedBy")} disabled sx={{marginRight:"10px"}} />
            <TextInput label = "Uploaded on" source={getSource("uploadDate")} disabled  />
        </div>
        { record.active ? <TextInput label = "Set Active by" source={getSource("setActiveBy")} disabled  sx={{margin:"0"}}/> : null}
    </div>
    </div>
    )
  }