import {useEffect, useState} from 'react'
import {  
    List,  
    useListContext,
    useDataProvider,
    SelectInput,
    SelectArrayInput,
} from 'react-admin';
import {Table,TableBody,TableHead,TableCell,TableContainer,TableRow,Paper } from '@mui/material'


  export const ViewMonthlyAnalyticsListShorts = () => {
    const [ownChannels, setOwnChannels] = useState([])
    const dataProvider = useDataProvider()
    useEffect(()=>{
      dataProvider.getList('ownShortChannels', {pagination: {page: 1, perPage: 500}, sort: {field: 'id', order: 'ASC'}}).then(result=>{
        let data = result.data.map(channel=>{return {id: channel.id, name: channel.name}})
        setOwnChannels(data)
      }
      ).catch()
      if (typeof window.plausible === 'function') {
        const userEmail = localStorage.getItem('email')
        window.plausible('Show Monthly Shorts Views', {props: {user: userEmail}})
      }
    },[])
  return (
  <List pagination={false} filters={getChannelFilters(ownChannels)}  >
        <UserTable/>
    </List>
  )
  }

  const getChannelFilters = (ownChannels) => [<SelectArrayInput source ='channels' choices={ownChannels} alwaysOn/>,<SelectInput source ='team' choices={[{id:"US", name:"US"},{id:"VN", name:"VN"}]} emptyText="All Teams" alwaysOn/>]


  const UserTable = ()=>{
    const {data} = useListContext()    
    if(!data || data.length === 0) return <p> No Data yet</p>
    let columnNames = Object.keys(data[0])
    columnNames = columnNames.filter((name)=>name!=="id")
    return (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}  aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnNames.map((name, index)=><TableCell key={index} sx={{minWidth:"80px"}}><b>{name}</b></TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={row.id%2===0 ? {backgroundColor:"#64B4B8",'&:last-child td, &:last-child th': { border: 0 }}:{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columnNames.map((name,index)=><TableCell key={index} component="th" scope="row">
                {row[name]?.toLocaleString()}
              </TableCell>)}
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )   
  }