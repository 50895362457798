// in src/App.js
import * as React from "react";
import { Admin, Resource, CustomRoutes, addRefreshAuthToDataProvider, nanoLightTheme, nanoDarkTheme } from 'react-admin';
import { Route, BrowserRouter } from "react-router-dom";
import LoginPage from "./admin/LoginPage";
import Dashboard from "./components/DashBoard";
import { OwnChannelList } from "./components/ownChannels/ownChannels";
import OwnChannelShow from "./components/ownChannels/ShowOwnChannel"
import { OwnVideoList } from "./components/ownChannels/ownVideos";
import { OwnVideoShow } from "./components/ownChannels/ShowOwnVideo"
import { OwnVideoCreate } from "./components/ownChannels/CreateOwnVideo";
import { ChannelList, ChannelCreate, ExternalChannelShow } from "./components/externalChannels/externalChannels";
import {ExternalVideoList} from "./components/externalChannels/externalVideos";
import ExternalVideoShow from "./components/externalChannels/ShowExternalVideo";
import FlocalDataProvider from "./admin/dataProviders/FlocalDataProvider";
import authProvider,{refreshAuth} from "./admin/authProvider";
import { MyMenuLayout } from './admin/menu';
import { UserList, UserCreate, UserEdit } from "./components/users/users";
import {RolesList, RolesCreate, RolesEdit} from "./components/users/roles";
import { ViewAnalyticsList } from "./components/users/viewAnalytics/ListViewAnalytics";
import {ViewAnalyticsShow} from "./components/users/viewAnalytics/ShowViewAnalytics";
import {ViewAnalyticsListShortsLastXDays} from "./components/users/viewAnalytics/ListViewAnalyticsShortsLastXDays";
import { ViewAnalyticsListLastXDays } from "./components/users/viewAnalytics/ListViewAnalyticsLastXDays";
import {MonthlyViewAnalyticsList} from "./components/users/viewAnalytics/ListMonthlyViewAnalytics";
import {ViewMonthlyAnalyticsListShorts} from "./components/users/viewAnalytics/ListMonthlyViewAnalyticsShorts";
import { ThumbnailsCreate, ThumbnailsEdit } from "./components/ownChannels/thumbnails";
import {Chat} from "./components/chatGpt/chat";
import {ImageHandler} from "./components/chatGpt/imageHandling";
import {VideoScriptGenerator} from "./components/chatGpt/videoScriptGenerator";
import { VideoScriptFixer } from "./components/chatGpt/videoScriptFixer";
import {YoutubeOAuth} from "./components/miscellanious/youtubeOauth";
import { VideoPerformances } from "./components/ownChannels/VideoPerformances";
import {ProjectsList, ProjectsShow, ProjectsCreate, ProjectEdit} from "./components/projects/Projects";
import ShowVideoIdeas, {SavedVideoIdeas} from "./components/videoIdeas/videoIdeas";
import { Prompts } from "./components/chatGpt/prompts";
import { OwnShortList } from "./components/shorts/ownShorts";
import { ExternalShortList } from "./components/shorts/externalShorts";
 

const dataProvider = addRefreshAuthToDataProvider(FlocalDataProvider, refreshAuth);

const App = () => {

  // React.useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "https://analytics.ntmstat.com/js/script.js";
  //   script.defer = true;
  //   script.setAttribute('data-domain', 'ntmdata.com');

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

return(
<BrowserRouter>
  <Admin lightTheme={nanoLightTheme} darkTheme={nanoDarkTheme} dashboard={Dashboard}  dataProvider={dataProvider} layout={MyMenuLayout} authProvider = {authProvider} loginPage={LoginPage}>
    {permissions =>(
      <>
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name="projects" list = {ProjectsList} show={ProjectsShow} create={ProjectsCreate} edit={ProjectEdit}/> : null }
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name="videoIdeas" list = {ShowVideoIdeas}/> : null }
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name="savedVideoIdeas" list = {SavedVideoIdeas}/> : null }
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser' ) ? <Resource name="ownChannels"  list = {OwnChannelList} show = {OwnChannelShow}/> : null } 
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name = "ownVideos" list = {OwnVideoList} show = {OwnVideoShow} create={(permissions === 'superuser') ? OwnVideoCreate : null}  /> :null }
       { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <CustomRoutes><Route path="/videoPerformances" element={<VideoPerformances />} /></CustomRoutes> : null }
      <Resource name="externalChannels" list={ChannelList} create={(permissions === 'superuser' || permissions === 'manager') ? ChannelCreate : null} recordRepresentation='title' show ={ExternalChannelShow}/>
      <Resource name="externalVideos"  list = {ExternalVideoList} show={ExternalVideoShow}/>
      { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name = "ownShorts" list = {OwnShortList} /> :null }
      { (permissions === 'superuser' || permissions === 'manager' || permissions === 'standarduser') ? <Resource name = "externalShorts" list = {ExternalShortList} /> :null }
      {/* <Resource name="videoProposals" list = {VideoProposalsList} show={VideoProposalShow} create={VideoProposalCreate} edit={VideoProposalEdit}/>
      <Resource name="propsProposals" list = {PropsProposalsList} show={PropsProposalShow} create={PropsProposalCreate} edit={PropsProposalEdit}/> */}
      {/* <Resource name="trendingTags" list = {TrendingTagsList} show={TrendingTagsShow}  create={TrendingTagsCreate}/> */}
      <Resource name="userData" list = {UserList} create ={(permissions === 'superuser' || permissions === 'manager') ? UserCreate : null} edit={(permissions === 'superuser' || permissions === 'manager') ? UserEdit : null}/>
      <Resource name="viewsAnalytics" list = {ViewAnalyticsList} show={ViewAnalyticsShow}/>
      <Resource name="ViewAnalyticsLastXDays" list = {ViewAnalyticsListLastXDays}/>
      <Resource name="monthlyViewsAnalytics" list = {MonthlyViewAnalyticsList}/>
      <Resource name="ViewAnalyticsShortsLastXDays" list = {ViewAnalyticsListShortsLastXDays}/>
      <Resource name="monthlyShortsViewAnalytics" list = {ViewMonthlyAnalyticsListShorts}/>
      { (permissions === 'superuser' || permissions === 'manager') ? 
        <>
        <Resource name="roles" list = {RolesList} create ={RolesCreate} edit={RolesEdit}/>
        <Resource name="thumbnails"  create={ThumbnailsCreate} edit={ThumbnailsEdit}/>
        </>
      : null } 
      <CustomRoutes>
            <Route path="/chat" element={<Chat />} />
            <Route path="/prompts" element={<Prompts />} />
            <Route path="/videoScriptGenerator" element={<VideoScriptGenerator />} />
            <Route path="/videoScriptFixer" element={<VideoScriptFixer />} />
            <Route path="/imageHandler" element={<ImageHandler />} />
            <Route path="/youtubeOAuth" element={<YoutubeOAuth />} />
        </CustomRoutes>
    </>
    )}
  </Admin>
  </BrowserRouter>
);
}
export default App;