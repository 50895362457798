import axios from 'axios'
import endpoints from '../endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getVideoCreatorsForShort = async(params)=>{
    const result = await axios.get(endpoints.backendUrl+endpoints.shortsVideoCreatorEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params})
    return {data: result.data}
}

export const getRolesForShorts = async()=>{
    const result = await axios.get(endpoints.backendUrl+endpoints.shortsVideoCreatorEndpoint+'/roles', {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data: result.data}
}

export const addVideoCreatorsForShort = async(params)=>{    
    const rawData = await axios.post(endpoints.backendUrl+endpoints.shortsVideoCreatorEndpoint,params,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    console.log(rawData)
    return {data:{id:0}}
}

export const updateVideoCreatorsForShort = async(params)=>{
    const rawData = await axios.put(endpoints.backendUrl+endpoints.shortsVideoCreatorEndpoint, params, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    console.log(rawData)
    return {data:rawData}
}

export const deleteVideoCreatorsForShort = async(params)=>{
    await axios.delete(endpoints.backendUrl+endpoints.shortsVideoCreatorEndpoint,{headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, data: params})
    return {data:{id:0}}
}