import axios from 'axios'
import endpoints from '../endpoints.json'
import { HttpError } from 'react-admin';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getOwnShorts = async(params)=>{
    const { page, perPage } = params.pagination;
    const queryParams ={
        page:page > 1 ? (page-1)*perPage : 0,
        perPage: perPage,
        sort: params.sort.field,
        order: params.sort.order,
        channelId: params.filter.Channel || 0,
        searchPhrase: params.filter.searchPhrase || ''
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownShortsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams}) 
    if(rawData.status !== 200) throw new HttpError(rawData.statusText,rawData.status)
    const data = rawData.data.map(short=>{
        return {
            ...short,
            title: short.title,
            publish_date: short.publish_date?.substring(0,short.publish_date.lastIndexOf(':')).replace("T"," ").replace("00:00",""),
            views: Number.parseInt(short.views || 0).toLocaleString(),
            views_last_2h: (short.views_last_2h || 0).toLocaleString(),
            views_last_24h: (short.views_last_24h || 0).toLocaleString(),
            retention_percentage: (short.retention_percentage || 0) + '%',
            retention_percentage_first_ten_seconds: (Math.round(short.retention_percentage_first_ten_seconds * 10000)/100 || 0) + '%',
            retention_first_thirty_seconds: (Math.round(short.retention_first_thirty_seconds * 10000)/100 || 0) + '%'
        }
    })
    return {data:data,pageInfo: {hasPreviousPage: page > 0 ? true : false, hasNextPage: data.length < perPage ? false : true}}
        
}

export const getOwnShortsChannels = async()=>{
    const queryParams ={
        project:1 // hardcoded project id for the moment to only show Kids Channels
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.ownShortsChannelsEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    return{data:rawData.data, pageInfo: {hasPreviousPage: false, hasNextPage: false}}
}