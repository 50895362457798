import axios from 'axios'
import endpoints from './endpoints.json'

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const getMonthlyViewAnalytics = async(params)=>{
    const { page, perPage } = params.pagination;
    const showTotal = params.filter.showTotal
    const queryParams ={
        startDate: getDateFromXMonthsAgo(page*perPage),
        endDate: getDateFromXMonthsAgo(page*perPage-perPage),
        team: showTotal ? "all" : params.filter.team,
        searchPhrase: showTotal ? "" : params.filter.searchPhrase,
        sort: params.sort.field,
        order: params.sort.field === 'id' ? 'DESC' : params.sort.order,
        channel: params.filter.channel
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.monthlyViewsAnalyticsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: queryParams})
    //console.log(rawData.data.filter(item => item.videoCreators_id === 34))
    let mappedViews = []
    if(rawData.data.length > 0){
        const namesObject = mapNamesToObject(rawData.data)
        mappedViews = convertData(rawData.data, namesObject)
    }
    return {data:mappedViews, total:mappedViews.length}
}

export const getDailyViewAnalytics = async(params)=>{
    let date = params.filter.date
    if(checkDate(date) === false){
        return {data:[], total:0}
    }
    const rawData = await axios.get(endpoints.backendUrl+endpoints.viewsAnalyticsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params.filter})
    let processedData = []
    let totalDailyViewsUS = 0
    let totalDailyViewsVN = 0
    let totalViewsMonthToDateUS = 0
    let totalViewsMonthToDateVN = 0
    //console.log(JSON.stringify(rawData))
    for (const item of rawData.data){
        if(item.team === "US"){
            totalDailyViewsUS = totalDailyViewsUS + item.views
            totalViewsMonthToDateUS = totalViewsMonthToDateUS + item.viewsMonthToDate
        }
        else{
            totalDailyViewsVN = totalDailyViewsVN + item.views
            totalViewsMonthToDateVN = totalViewsMonthToDateVN + item.viewsMonthToDate
        }
        const index = processedData.findIndex(x => x.videoCreators_id === item.videoCreators_id)
        if (index === -1){
            processedData.push(item)
        }
        else{
            processedData[index].views = processedData[index].views + item.views
            processedData[index].viewsMonthToDate = processedData[index].viewsMonthToDate + item.viewsMonthToDate
        }
    }
    const mappedData = processedData.map( entry=> { 
        return{
            id: entry.videoCreators_id, //using the videoCreatorID for the id to be able to load the videoCreator on Show
            date: entry.date.slice(0,entry.date.lastIndexOf('T')),
            views: entry.views.toLocaleString(),
            viewsMonthToDate: entry.viewsMonthToDate?.toLocaleString(),
            name: entry.name,
            totalDailyViewsUS: totalDailyViewsUS,
            totalDailyViewsVN: totalDailyViewsVN,
            totalViewsMonthToDateUS: totalViewsMonthToDateUS,
            totalViewsMonthToDateVN: totalViewsMonthToDateVN,
        }})
    return {data:mappedData, total:mappedData.length}
}

export const getViewAnalyticsLastXDays = async(params)=>{
    let date = params.filter.date
    if(checkDate(date) === false){
        return {data:[], total:0}
    }
    params.filter.date =getDateFromXMonthsAgo(2)
    const rawData = await axios.get(endpoints.backendUrl+endpoints.viewsAnalyticsEndPoint+"LastXDays", {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params.filter})
    let mappedViews = []
    if(rawData.data.length > 0){
        const namesObject = mapNamesToObject(rawData.data)
        mappedViews = convertData(rawData.data, namesObject)
    }
   // console.log(mappedViews)
    return {data:mappedViews, total:mappedViews.length}
}

export const getShortsViewAnalyticsLastXDays = async(params)=>{
    params.filter.date =getDateFromXMonthsAgo(2)
    const data = await axios.get(endpoints.backendUrl+endpoints.dailyShortsViewsAnalyticsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params.filter})
   let mappedData =[];
   data.data.forEach((item,index) => {
    let row ={id:index, date:item.date.slice(0,item.date.lastIndexOf('T')), Total:Number(item.total_views), USA:Number(item.us_views), Vietnam:Number(item.vn_views)};
    item.video_creators.forEach((creator,index)=>{
        const creatorName = creator.replaceAll(' ','-');
        if(row[creatorName])
            row[creatorName] = Number(row[creatorName]) + Number(item.views_array[index]);
        else
            row[creatorName] = Number(item.views_array[index]);
    })
    mappedData.push(row);
   })
    return {data:mappedData, total:mappedData.length}
}

export const getMonthlyShortsViewAnalytics = async(params)=>{
    const data = await axios.get(endpoints.backendUrl+endpoints.monthlyShortsViewsAnalyticsEndPoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}, params: params.filter})
   let mappedData =[];
   data.data.forEach((item,index) => {
    let row ={id:index, date:item.date.slice(0,item.date.lastIndexOf('T')), Total:Number(item.total_views), USA:Number(item.us_views), Vietnam:Number(item.vn_views)};
    item.video_creators.forEach((creator,index)=>{
        const creatorName = creator.replaceAll(' ','-');
        if(row[creatorName])
            row[creatorName] = Number(row[creatorName]) + Number(item.views_array[index]);
        else
            row[creatorName] = Number(item.views_array[index]);
    })
    mappedData.push(row);
   })
    return {data:mappedData, total:mappedData.length}
}

export const getViewAnalyticsByVideoCreatorId = async(params)=>{
    // console.log(params)
    const date = params.meta ? params.meta.date : new Date().toISOString().split('T')[0]
    const rawData = await axios.get(endpoints.backendUrl+endpoints.viewsAnalyticsEndPoint+'/'+params.id+'?date='+date, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}} )
    let monthlyViewsArr = rawData.data[0]
    let dailyViewsArr = rawData.data[1]
    let dailyViewsPerChannel =[]
    let monthlyViewsPerChannel =[]
    for(const item of dailyViewsArr){
        const index = dailyViewsPerChannel.findIndex(x => x.owned_channels_id === item.owned_channels_id)

        if (index === -1){     
            const channelData =[]
            channelData.push({
                            date: item.date.slice(0,item.date.lastIndexOf('T')), 
                            views: item.views,
                            viewsMonthToDate: item.viewsMonthToDate
                        })
            dailyViewsPerChannel.push({owned_channels_id: item.owned_channels_id, channelName:item.channelName, dailyViews:channelData})
        }
        else{
            dailyViewsPerChannel[index].dailyViews.push({
                        date: item.date.slice(0,item.date.lastIndexOf('T')), 
                        views: item.views,
                        viewsMonthToDate: item.viewsMonthToDate
                    })
        }
    }
    for(const item of monthlyViewsArr){
        const index = monthlyViewsPerChannel.findIndex(x => x.channelName === item.channelName)

        if (index === -1){     
            const channelData =[]
            channelData.push({
                            date: item.date.slice(0,item.date.lastIndexOf('T')), 
                            viewsMonthToDate: item.viewsMonthToDate
                        })
                        monthlyViewsPerChannel.push({channelName:item.channelName, monthlyViews:channelData})
        }
        else{
            monthlyViewsPerChannel[index].monthlyViews.push({
                        date: item.date.slice(0,item.date.lastIndexOf('T')), 
                        viewsMonthToDate: item.viewsMonthToDate
                    })
        }
    }
    const totalViewsPerMonthThisYear = calculateTotalMonthlyViews(monthlyViewsPerChannel)
    
    return {data:{id:params.id, name:monthlyViewsArr[0].name, dailyViewsPerChannel:dailyViewsPerChannel, totalViewsPerMonthThisYear:totalViewsPerMonthThisYear}}
}

const calculateTotalMonthlyViews = (monthlyViewsPerChannel) =>{   
    let totalMonthlyViewsArr = []
    for(const channel of monthlyViewsPerChannel){
        for(const item of channel.monthlyViews){
            const index = totalMonthlyViewsArr.findIndex(x => x.date === item.date)
            if (index === -1){ 
                totalMonthlyViewsArr.push({date:item.date, viewsMonthToDate:item.viewsMonthToDate})
            }
            else{
                totalMonthlyViewsArr[index].viewsMonthToDate = totalMonthlyViewsArr[index].viewsMonthToDate + item.viewsMonthToDate
            }
        }
    }
    return totalMonthlyViewsArr
}
// check if date string is in future
const checkDate = (dateToCheck) => {
const date = new Date()
const threeDaysAgo = date.setDate(date.getDate() - 3)
const selectedDate = new Date(dateToCheck)
if(selectedDate > threeDaysAgo){
    return false
}
return true
}
//get date from x months ago
const getDateFromXMonthsAgo = (monthsAgo) => {
const date = new Date()
const xMonthsAgo = date.setMonth(date.getMonth() - monthsAgo)
return new Date(xMonthsAgo).toISOString().slice(0,10)
}

const mapNamesToObject = (data) =>{
    let namesObject = {id:0,Date:"",Total:0,US:0, VN:0}
    const firstDate = data[0].date
    let inc = 0
    while(data[inc]?.date === firstDate){
        if(!namesObject.hasOwnProperty(data[inc]?.name))
            namesObject[data[inc].name] = 0
        inc ++
    } 
    namesObject.NotSet=0
    return namesObject
}

const convertData = (data,namesObject) =>{
    let convertedData = []
    let index = 0
    convertedData[index] = {...namesObject}
    convertedData[index].Date = data[0].date.replace("T00:00:00.000Z","")
    let currentDate = data[0].date.replace("T00:00:00.000Z","")
    data.forEach(item => {
        let name = item.name === "NOT SET" ? "NotSet" : item.name
        let date = item.date.replace("T00:00:00.000Z","")
        if(date === currentDate){
            convertedData[index].Total += item.views
            if(item.team === "US")
                convertedData[index].US += item.views
            else if(item.team === "VN")
                convertedData[index].VN += item.views
            convertedData[index][name]=== 0 ? convertedData[index][name]=item.views : convertedData[index][name]+=item.views
        }
        else{
            index++
            convertedData[index] = {...namesObject}
            convertedData[index].id=index
            convertedData[index].Date = date
            convertedData[index][name] = item.views
            convertedData[index].Total = item.views
            if(item.team === "US")
                convertedData[index].US = item.views
            else if(item.team === "VN")
                convertedData[index].VN = item.views
            currentDate = date
        }
    })
    return convertedData
}
